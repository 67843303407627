import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import Layout from "../components/layout"
import { Link } from "gatsby"
import {
  PageHeader,
  Section,
  Content,
  Row,
  Col,
} from "../styles/StyledElements"
import { contentMapMarkdown } from "../components/pageUtilities"

const { slugFormat } = require("../utilities/slugFormat")

export default function Trends({ data, location }) {
  const markdownMap = contentMapMarkdown(data.markdown.nodes)
  const title = "Trends Index"
  return (
    <Layout location={location}>
      <Seo title={title} />
      <PageHeader>
        <Content>
          <Row>
            <Col>
              <h1>{title}</h1>
            </Col>
          </Row>
        </Content>
      </PageHeader>
      <Section>
        <Content>
          <Row>
            <Col>
              <div
                className={"description"}
                dangerouslySetInnerHTML={{
                  __html: markdownMap.get("description-trends").html,
                }}
              />
            </Col>
          </Row>
        </Content>
      </Section>
      <Section>
        <Content>
          <Row>
            <Col>
              <ul>
                {data.allAirtable.nodes.map(node => (
                  <li key={node.recordId}>
                    <Link to={`/trends/${slugFormat(node.data.Name)}`}>
                      <strong>{node.data.Name}:</strong> {node.data.Summary}
                    </Link>
                  </li>
                ))}
              </ul>{" "}
            </Col>
          </Row>
          {/* <CardsSet type={"trend"} nodes={data.forecasts.nodes} /> */}
        </Content>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  {
    allAirtable(
      filter: { table: { eq: "Trends" } }
      sort: { fields: data___Name, order: ASC }
    ) {
      nodes {
        recordId
        data {
          Name
          Summary
          Signals {
            data {
              Name
            }
            recordId
          }
        }
      }
    }
    markdown: allMarkdownRemark(
      filter: { frontmatter: { section: { in: ["descriptions"] } } }
    ) {
      nodes {
        html
        frontmatter {
          ref
          section
          title
        }
      }
    }
  }
`
